import React from "react"
import "./App.scss"

function App() {
  return (
    <section className="notFound">
      <div>page not found</div>
    </section>
  )
}

export default App
